$(function() {
  initMap();
  initLabForm();
  initItalyForm();
  initReviews();
  //
  //var controller = new ScrollMagic.Controller({
  //  globalSceneOptions: {triggerHook: "onEnter", duration: "200%"}
  //});
  //
  //new ScrollMagic.Scene({triggerElement: "#parallax1"})
  //               .setTween("#parallax1 > div", {y: "80%", ease: Linear.easeNone})
  //               .addIndicators()
  //               .addTo(controller);
});


function initMap() {
  var map = new google.maps.Map($('.map__container')[0], {
    center: { lat: 56, lng: 88 },
    zoom: 3,
    scrollwheel: false,
    navigationControl: false,
    mapTypeControl: false,
    scaleControl: false
  });

  var markers = [
    {
      "label": 21,
      "title": "Электролиз цинка",
      "lat": 55.627870,
      "lng": 61.283313
    },
    {
      "label": 1,
      "title": "Очистные сооружения",
      "lat": 52.86912,
      "lng": 39.39697
    },
    {
      "label": 1,
      "title": "Очистные сооружения",
      "lat": 47.83897,
      "lng": 35.15625
    },
    {
      "label": 1,
      "title": "Удобрения",
      "lat": 48.56024,
      "lng": 34.0686
    },
    {
      "label": 1,
      "title": "Очистные сооружения",
      "lat": 52.86912,
      "lng": 39.39697
    },
    {
      "label": 8,
      "title": "Фильтрация свинецсодержащих продуктов",
      "lat": 50.37349,
      "lng": 83.45214
    },
    {
      "label": 20,
      "title": "Электролиз цинка",
      "lat": 50.37371,
      "lng": 82.12245
    },
    {
      "label": 3,
      "title": "Фильтрация красных шламов",
      "lat": 58.715233,
      "lng": 60.524805
    },
    {
      "label": 8,
      "title": "Фильтрация красных шламов",
      "lat": 47.04018,
      "lng": 33.04687
    },
    {
      "label": 1,
      "title": "Фильтрация фосфатов",
      "lat": 59.659648,
      "lng": 32.641939
    },
    {
      "label": 4,
      "title": "Очистные сооружения",
      "lat": 58.06625,
      "lng": 56.07421
    },
    {
      "label": 16,
      "title": "Очистные сооружения",
      "lat": 55.75184,
      "lng": 38.30932
    },
    {
      "label": 1,
      "title": "Фильтрация красных шламов",
      "lat": 59.68992,
      "lng": 34.91455
    },
    {
      "label": 2,
      "title": "Фильтрация хрома",
      "lat": 57.50402,
      "lng": 59.11743
    },
    {
      "label": 3,
      "title": "Переработка аккумуляторов",
      "lat": 55.04061,
      "lng": 39.19921
    },
    {
      "label": 2,
      "title": "Переработка аккумуляторов",
      "lat": 52.74959,
      "lng": 102.1289
    },
    {
      "label": 1,
      "title": "Цеолит",
      "lat": 55.26659,
      "lng": 62.49023
    },
    //{
    //  "label": 1,
    //  "title": "Переработка аккумуляторов",
    //  "lat": 49.38237,
    //  "lng": 34.27734
    //},
    {
      "label": 2,
      "title": "Фосфаты",
      "lat": 51.34507,
      "lng": 47.52679
    },
    {
      "label": 1,
      "title": "Пигменты",
      "lat": 55.75189,
      "lng": 37.06787
    },
    {
      "label": 3,
      "title": "Переработка аккумуляторов",
      "lat": 49.38237,
      "lng": 34.27734
    },
    {
      "label": 3,
      "title": "Аффинаж золота и очистка стоков",
      "lat": 55.235039,
      "lng": 60.421591
    },
    {
      "label": 1,
      "title": "Переработка аккумуляторов",
      "lat": 50.48547,
      "lng": 79.89257
    },
    {
      "label": 1,
      "title": "Фильтрация полиоксихолида алюминия",
      "lat": 55.610911,
      "lng": 47.743306
    },
    {
      "label": 1,
      "title": "Нитрат кальция",
      "lat": 58.08949,
      "lng": 41.52832
    },
    {
      "label": 3,
      "title": "Шламы осветлителей",
      "lat": 55.235039,
      "lng": 60.421591
    },
    {
      "label": 1,
      "title": "Фильтрация глины",
      "lat": 55.21649,
      "lng": 36.21093
    },
    {
      "label": 4,
      "title": "Фильтрация нитрата магния и нитрата кальция",
      "lat": 59.34439,
      "lng": 49.83398
    },
    {
      "label": 3,
      "title": "Фильтрация пентоксида ванадия",
      "lat": 54.130005,
      "lng": 37.736223
    },
    {
      "label": 3,
      "title": "Гальванические шламы",
      "lat": 59.587507,
      "lng": 28.503700
    },
    {
      "label": 2,
      "title": "Шламы газоочистки",
      "lat": 56.88483,
      "lng": 57.92826
    },
    {
      "label": 1,
      "title": "Серная кислота",
      "lat": 56.93298,
      "lng": 60.68847
    },
    {
      "label": 4,
      "title": "Фильтрация хвостов цианирования золотоизвлекающей фабрики",
      "lat": 61.93671,
      "lng": 168.22547
    },
    {
      "label": 3,
      "title": "Медный концентрат",
      "lat": 53.641407,
      "lng": 59.374560
    },
    {
      "label": 2,
      "title": "Шламы осветлителей",
      "lat": 52.898903,
      "lng": 55.964056
    },
    {
      "label": 1,
      "title": "Фильтрация окида титана",
      "lat": 53.243442,
      "lng": 45.731129
    },
    {
      "label": 1,
      "title": "Медный концентрат",
      "lat": 57.54531,
      "lng": 60.49072
    },
    {
      "label": 1,
      "title": "Сульфат аммония",
      "lat": 66.99884,
      "lng": 33.15673
    },
    {
      "label": 1,
      "title": "Очистные сооружения",
      "lat": 49.68184,
      "lng": 82.11181
    },
    {
      "label": 1,
      "title": "Фосфаты",
      "lat": 51.2344,
      "lng": 39.06738
    },
    {
      "label": 1,
      "title": "Фильтрация белка",
      "lat": 51.2344,
      "lng": 36.36474
    },
    {
      "label": 2,
      "title": "Шламы осветлителей",
      "lat": 55.52552,
      "lng": 53.44848
    },
    {
      "label": 4,
      "title": "Концентраты",
      "lat": 52.37559,
      "lng": 82.17773
    }
  ];

  markers.forEach(function(m) {
    var title = m.title + ' — ' + m.label;

    var infoWindow = new google.maps.InfoWindow({
      content: title
    });

    var marker = new google.maps.Marker({
      map: map,
      position: { lat: m.lat, lng: m.lng },
      label: m.label > 9 ? '∞' : m.label.toString(),
      title: title
    });

    marker.addListener('click', function() {
      infoWindow.open(map, marker);
    });
  });
}

function disableForm(_$form) {
  _$form.find(':input').attr('disabled', true);
  _$form.addClass('disabled-form');
}

function enableForm(_$form) {
  _$form.find(':input').attr('disabled', false);
  _$form.removeClass('disabled-form');
}

function resetForm(_$form) {
  _$form.find(':text').val('');
}

function checkForm(_$form) {
  var wasError = false;

  _$form.find(':text').each(function(_i, _ele) {
    var $item = $(_ele);
    var isError = $.trim($item.val()) == '';
    $item.toggleClass('error', isError);
    wasError = wasError || isError;
  });

  return !wasError;
}

function clickPopup(_e) {
  _e.stopPropagation();
}

function closePopup(_$parent) {
  var $shelter = _$parent.find('.popup-shelter');
  $shelter.hide();

  var $popup = _$parent.find('.popup');
  $popup.hide();

  $popup.unbind('click', clickPopup);
  $popup.find('.popup__close').unbind('click.popupEvents');
  $(document).unbind('click.popupEvents');
}

function closePopupByClass(_class, _eventTypesToStop) {
  var $shelter = $('.' + _class + '-shelter');
  $shelter.hide();

  var $popup = $('.' + _class);
  $popup.hide();

  $popup.unbind('click', clickPopup);
  $popup.find('.popup__close').unbind(_eventTypesToStop);
  $(document).unbind(_eventTypesToStop);
}

function showPopup(_$parent, _message, _isError) {
  var $shelter = _$parent.find('.popup-shelter');
  $shelter.show();

  var $popup = _$parent.find('.popup');
  $popup.toggleClass('popup-error', _isError === true);
  $popup.find('.popup__message').html(_message);
  $popup.show();

  $popup.bind('click', clickPopup);

  $popup.find('.popup__close').bind('click.popupEvents', function() {
    closePopup(_$parent);
  });

  $(document).bind('click.popupEvents', function() {
    closePopup(_$parent);
  });
}

function initLabForm() {
  $('.evaluation .evaluation__submit').click(function(_e) {
    _e.stopPropagation();
    var eventTypes = 'click.labPopupEvents';

    var $shelter = $('.lab-form-shelter');
    $shelter.show();

    var $popup = $('.lab-form');
    $popup.show();

    $popup.bind('click', clickPopup);

    $popup.find('.popup__close').bind(eventTypes, function() {
      closePopupByClass('lab-form', eventTypes);
    });

    $(document).bind(eventTypes, function() {
      closePopupByClass('lab-form', eventTypes);
    });
  });

  $('.lab-form form').submit(function(_e) {
    _e.preventDefault();

    var $form = $(_e.target);
    if (!checkForm($form)) return;

    var data = $form.serialize();
    //var csrftoken = Cookies.get('csrftoken');

    disableForm($form);

    $.ajax($form.attr('action'), {
      'method': 'post',
      'data': data,
      //'headers': {'HTTP_X_CSRFTOKEN': csrftoken},
      'success': function() {
        enableForm($form);
        resetForm($form);
        showPopup(
          $form,
          'Информация от&nbsp;Вас сохранена. В&nbsp;ближайшее время мы ' +
          'свяжемся с&nbsp;Вами. Спасибо за&nbsp;проявленный интерес.'
        );
      },
      'error': function() {
        enableForm($form);
        showPopup(
          $form,
          'Произошла ошибка. Пожалуйста, свяжитесь ' +
          'с&nbsp;нами по&nbsp;контактным телефонам, ' +
          'указанным на&nbsp;странице.',
          true
        );
      }
    });
  });
}

function initItalyForm() {
  $('.italy-form form').submit(function(_e) {
    _e.preventDefault();

    var $form = $(_e.target);
    if (!checkForm($form)) return;

    var data = $form.serialize();
    //var csrftoken = Cookies.get('csrftoken');

    disableForm($form);

    $.ajax($form.attr('action'), {
      'method': 'post',
      'data': data,
      //'headers': {'HTTP_X_CSRFTOKEN': csrftoken},
      'success': function() {
        enableForm($form);
        resetForm($form);
        showPopup(
          $form,
          'Информация от&nbsp;Вас сохранена. В&nbsp;ближайшее время мы ' +
          'свяжемся с&nbsp;Вами. Спасибо за&nbsp;проявленный интерес.'
        );
      },
      'error': function() {
        enableForm($form);
        showPopup(
          $form,
          'Произошла ошибка. Пожалуйста, свяжитесь ' +
          'с&nbsp;нами по&nbsp;контактным телефонам, ' +
          'указанным на&nbsp;странице.',
          true
        );
      }
    });
  });
}

function initReviews() {
  var review = null;
  var $navItems = $('.reviews-nav li');
  var $reviews = $('.reviews-list li');

  function turnReviewOff(_i) {
    $($reviews[_i]).removeClass('active');
    $($navItems[_i]).removeClass('active');
  }

  function turnReviewOn(_i) {
    review = _i;
    $($reviews[_i]).addClass('active');
    $($navItems[_i]).addClass('active');
  }

  $navItems.each(function(_i) {
    $(this).click(function() {
      turnReviewOff(review);
      turnReviewOn(_i);
    });
  });

  turnReviewOn(0);
}
